<template>
  <AModal no-bottom-padding-for-header @close="$emit('close')">
    <template #header>
      <ATranslationTabs v-model:selected-language-code="selectedLanguageCode" />
    </template>

    <div>
      <div class="space-y-4">
        <AutoTranslateToggle
          v-model:auto-translate="form.autoTranslate"
          :selected-language-code="selectedLanguageCode"
        />

        <AInput
          v-model="form.name[selectedLanguageCode]"
          label="Order type name"
          placeholder=""
          :error="form.errors[`name.${selectedLanguageCode}`]"
          :mandatory="isPrimaryLanguageSelected"
          :disabled="areTranslatableFieldsDisabled"
        />

        <AInput
          v-model="form.description[selectedLanguageCode]"
          label="Order type description"
          placeholder=""
          :error="form.errors[`description.${selectedLanguageCode}`]"
          type="textarea"
          :rows="3"
          :disabled="areTranslatableFieldsDisabled"
        />

        <ASelect
          v-model="form.type"
          label="Type"
          :options="types"
          :value-resolver="(option) => option.type"
          :text-resolver="(option) => option.label"
          empty-item-text="Select type"
          empty-item-disabled
          :error="form.errors.type"
        />
      </div>
    </div>

    <template #footer>
      <div class="flex space-x-2">
        <AButton class="mt-3" :loading="form.processing" @click="submit">
          {{ existingOrderType ? 'Save order type' : 'Create order type' }}
        </AButton>
        <AButton variant="outline-secondary" @click="$emit('close')">
          Cancel
        </AButton>
      </div>
    </template>
  </AModal>
</template>
<script>
import AInput from '@app/Shared/AInput.vue';
import AButton from '@app/Shared/AButton.vue';
import ASelect from '@app/Shared/ASelect.vue';
import { useForm } from '@inertiajs/vue3';
import AModal from '@app/Shared/AModal.vue';
import ATranslationTabs from '@app/Shared/ATranslationTabs.vue';
import HasTranslationTabs from '@app/Mixins/HasTranslationTabs.js';
import cloneDeep from 'lodash/cloneDeep.js';
import AutoTranslateToggle from '@app/Shared/AutoTranslateToggle.vue';

export default {
  components: {
    AutoTranslateToggle,
    ATranslationTabs,
    AModal,
    ASelect,
    AButton,
    AInput,
  },
  mixins: [HasTranslationTabs],
  props: {
    existingOrderType: {
      type: Object,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      form: useForm({
        autoTranslate: this.existingOrderType
          ? cloneDeep(this.existingOrderType.autoTranslate)
          : {},
        name: this.existingOrderType ? { ...this.existingOrderType.name } : {},
        description: this.existingOrderType
          ? { ...this.existingOrderType.description }
          : {},
        type: this.existingOrderType ? this.existingOrderType.type : null,
      }),
      types: [
        { type: 'CUSTOM', label: 'Custom' },
        { type: 'DELIVERY', label: 'Delivery' },
        { type: 'PICKUP', label: 'Pickup' },
        { type: 'TABLE', label: 'Table' },
      ],
    };
  },
  computed: {
    autoTranslatePath() {
      return 'form.autoTranslate';
    },
    translatablePaths() {
      return ['form.name', 'form.description'];
    },
  },
  methods: {
    async submit() {
      if (!this.existingOrderType) {
        await this.form.post('/order-types', {
          onSuccess: () => {
            this.$emit('close');
            this.$toast.success('Saved');
          },
          onError: () => {
            this.$toast.error('Please fix all highlighted fields');
          },
          preserveScroll: true,
        });
      } else {
        await this.form.put(`/order-types/${this.existingOrderType.id}`, {
          onSuccess: () => {
            this.$emit('close');
            this.$toast.success('Saved');
          },
          onError: () => {
            this.$toast.error('Please fix all highlighted fields');
          },
          preserveScroll: true,
        });
      }
    },
  },
};
</script>
